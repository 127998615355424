import { NextIntlClientProvider } from 'next-intl'
import Head from 'next/head'
import Script from 'next/script'
import { QueryClient, QueryClientProvider } from 'react-query'
import dynamic from 'next/dynamic'
import { isBoom } from '@hapi/boom'
import useCustomFonts from '@/app/hooks/useCustomFonts'
import {
  Notification,
  NotificationContextProvider,
} from '@/app/components/Notification'
import { useScrollRestoration } from '@/app/lib/useScrollRestoration'
import { AuthProvider } from '@/app/providers/Auth/Auth'
import { setDefaultOptions } from 'date-fns'
import { useState } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import myConfig from '../../tailwind.config'
import { PagesTopLoader } from 'nextjs-toploader/pages'
import { isPrivateRoute, logout } from '@/app/lib/auth'

const getQueryClient = (router) => {
  const { route: currentRoute } = router.state

  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        refetchOnMount: false,
        retry: (failureCount, error) => {
          if (isBoom(error, 401)) {
            return false
          }
          return failureCount < 4
        },
        onError: (error) => {
          if (isBoom(error, 401) && isPrivateRoute(currentRoute)) {
            logout(window.location.pathname)
          }
        },
      },
      mutations: {
        onError: (error) => {
          if (isBoom(error, 401)) {
            logout(window.location.pathname)
          }
        },
      },
    },
  })
}

function loadMSW() {
  if (process.env.NEXT_PUBLIC_API_MOCKING) {
    const { worker } = require('../mocks/browser')
    return worker.start()
  }

  return Promise.resolve()
}

function importDateLocale(locale: string): Promise<any> {
  switch (locale) {
    case 'en':
      return import('date-fns/locale/en-GB')
    case 'es':
      return import('date-fns/locale/es')
    case 'pt':
      return import('date-fns/locale/pt')
    case 'fr':
      return import('date-fns/locale/fr')
    case 'it':
      return import('date-fns/locale/it')
    case 'de':
      return import('date-fns/locale/de')
    default:
      return Promise.resolve()
  }
}

function MydraMarketplace({ Component, pageProps, router }) {
  const { theme } = resolveConfig(myConfig)
  const { inter, planarGt } = useCustomFonts()
  const [isLocaleLoaded, setIsLocaleLoaded] = useState(false)

  useScrollRestoration(router)

  importDateLocale(router.locale ?? pageProps.locale ?? 'en')
    .then(({ default: locale }) => {
      setDefaultOptions({ locale })
    })
    .finally(() => {
      setIsLocaleLoaded(true)
    })

  if (!isLocaleLoaded) {
    return null
  }

  return (
    <QueryClientProvider client={getQueryClient(router)}>
      <NextIntlClientProvider
        locale={router.locale}
        messages={pageProps.messages}
      >
        <AuthProvider>
          <div
            className={`${inter.variable} ${planarGt.variable} font-sans h-full flex flex-col`}
          >
            <Head>
              <title>Mydra | Courses Marketplace</title>
              <meta
                name="description"
                content="We've mapped the most in-demand skills, allowing to you stay ahead of the game.
          With our support, you'll not only prepare for the future; you'll lead it."
                key="desc"
              />
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
              />
              <link rel="manifest" href="/site.webmanifest" />
              <link
                rel="mask-icon"
                href="/safari-pinned-tab.svg"
                color="#5bbad5"
              />
              <meta name="msapplication-TileColor" content="#da532c" />
              <meta name="theme-color" content="#ffffff" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
              />
            </Head>
            <style jsx global>{`
              html {
                font-family: ${inter.style.fontFamily};
              }
            `}</style>
            <Script
              strategy="afterInteractive"
              src="https://www.googletagmanager.com/gtag/js?id=G-0BQX5T7N4T"
            />
            <Script id="google-analytics" strategy="afterInteractive">
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-0BQX5T7N4T');
              `}
            </Script>
            {Component.noChatBubble ? null : (
              <>
                <Script id="chat-bubble-1">
                  {`
                    !(function (e, t, n) {
                      function a() {
                        var e = t.getElementsByTagName("script")[0],
                          n = t.createElement("script");
                        (n.type = "text/javascript"),
                          (n.async = !0),
                          (n.src = "https://beacon-v2.helpscout.net"),
                          e.parentNode.insertBefore(n, e);
                      }
                      if (
                        ((e.Beacon = n =
                          function (t, n, a) {
                            e.Beacon.readyQueue.push({ method: t, options: n, data: a });
                          }),
                        (n.readyQueue = []),
                        "complete" === t.readyState)
                      )
                        return a();
                      e.attachEvent
                        ? e.attachEvent("onload", a)
                        : e.addEventListener("load", a, !1);
                    })(window, document, window.Beacon || function () {});
                `}
                </Script>
                <Script id="chat-bubble-2" strategy="lazyOnload">
                  {`window.Beacon('init', 'b0e8609e-0238-479b-99a1-1bcaceb5d598')`}
                </Script>
              </>
            )}
            <div
              className="elfsight-app-18d3b2c5-da16-4ae9-ab03-693d5ece43e2"
              data-elfsight-app-lazy
            ></div>
            <NotificationContextProvider>
              <PagesTopLoader color={theme.colors['mydra-purple']} />
              <Component {...pageProps} />
              <Notification />
            </NotificationContextProvider>
          </div>
        </AuthProvider>
      </NextIntlClientProvider>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
    </QueryClientProvider>
  )
}

const ReactQueryDevtools = dynamic(() =>
  import('react-query/devtools').then((mod) => mod.ReactQueryDevtools)
)

export default dynamic(
  () => Promise.resolve(loadMSW()).then(() => MydraMarketplace),
  { ssr: false }
)
