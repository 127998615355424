import { PRIVATE_ROUTES, REDIRECT_TO_URL_PATH_REGEX, ROUTES } from './constants'

export function logout(redirectTo = '/') {
  if (
    window.location.pathname.includes(ROUTES.logout) ||
    window.location.pathname.includes(ROUTES.login)
  ) {
    return
  }

  window.location.href = `${ROUTES.logout}?redirectTo=${redirectTo}`
}

export function isPrivateRoute(route: string) {
  return PRIVATE_ROUTES.some((privateRoute: string | RegExp) => {
    if (privateRoute instanceof RegExp) {
      return privateRoute.exec(route)
    }

    return privateRoute === route
  })
}
export function isValidRedirect(path: string) {
  return (
    ![
      ROUTES.login,
      ROUTES.authLogin,
      ROUTES.signup,
      ROUTES.accessDenied,
      ROUTES.notFound,
    ].includes(path) && path.match(REDIRECT_TO_URL_PATH_REGEX)
  )
}
